<template>
  <v-container class="container">
    <div class="sys-logo">
      <a href="/">
        <v-img
          class="mx-auto mt-4 mb-8"
          max-width="228"
          max-height="60"
          :src="logoSrc"
        ></v-img>
      </a>
      <slot></slot>
    </div>
  </v-container>
</template>

<script setup>
const logoSrc = computed(() => {
  return import.meta.env.VITE_PUBLIC_LOGO;
});
</script>

<style lang="scss" scoped>
.sys-logo {
  padding-top: 8vh;
}
.container {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  /* background: #fafafa; */
}
</style>
